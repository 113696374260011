import React from 'react';

// Layout import
import Layout from '../layouts/main';

// SEO imports
import SEO from '../components/seo';
import ogImage from '../assets/images/og-images/southern-edge-communications.jpg';

// Componenent imports
import Intro from '../components/intro';

// Intro props
let introProps = {
	title: '404: Page Not Found',
	description: 'The page you are looking for does not exist.',
	cta: 'Back to Home',
	link: '/'
};

const NotFoundPage = () => (
	<Layout>
		<SEO title="404: Page Not Found" description="Southern Edge Communications" image={ogImage} />
		<Intro {...introProps} />
	</Layout>
);

export default NotFoundPage;
